import { removePersistentData } from './storage';
import log from './utils/log';
import getRespondentId from './utils/respondentId';

if (!window.extellio_actions) {
  if (window.espace_paq) {
    log('espace_paq found');
    window.extellio_actions = window.espace_paq;
    window.espace_paq = {
      push: (...args) => {
        console.log('You are still using our old action queue "espace_paq", please update your code to use "extellio_actions" instead.');
        window.extellio_actions.push(...args);
      },
    };
  } else {
    window.extellio_actions = [];
  }
}

const isString = (arg) => typeof arg === 'string' || arg instanceof String;

const applyAction = (action) => {
  log('[Action]', action);

  if (!action) {
    return;
  }

  if (isString(action)) {
    action = [action];
  }

  if (!Array.isArray(action)) {
    log('Invalid action');
    return;
  }

  const [method, ...args] = action;

  if (!isString(method)) {
    log('Invalid action');
    return;
  }

  if (method === 'start') {
    window.extellio.start();
    return;
  }

  if (method === 'stop') {
    window.extellio.stop();
    return;
  }

  if (method === 'setConsentGiven' || method === 'rememberConsentGiven') {
    window.extellio.analyticalConsentGiven = true;
  }

  if (method === 'setCookieConsentGiven' || method === 'rememberCookieConsentGiven') {
    window.extellio.analyticalCookieConsentGiven = true;
    getRespondentId();
  }

  if (method === 'forgetConsentGiven') {
    window.extellio.analyticalConsentGiven = false;
    removePersistentData('respondentId');
  }

  if (method === 'forgetCookieConsentGiven') {
    window.extellio.analyticalCookieConsentGiven = false;
    removePersistentData('respondentId');
  }

  if (window.extellio.website?.reachedActionLimit && method.indexOf('track') === 0) {
    log('Not tracking action because of license limit');
    return;
  }

  const { tracker } = window.extellio;

  if (!tracker) {
    log('Action not executed, matomo not initialized', method);
    return;
  }

  if (method === 'trackEvent') {
    args.forEach((arg, index) => {
      if (typeof arg === 'string' && arg.length > 200) {
        args[index] = arg.substring(0, 200);
        log('Event argument is too long, truncating', arg);
      }
    });
  }

  let actionRes;

  if (method.indexOf('::') !== -1) {
    const [context, f] = method.split('::');
    if (!window.Matomo[context] || !window.Matomo[context][f]) {
      log('Invalid action', method);
      return;
    }
    actionRes = window.Matomo[context][f](...args);
  } else if (method.indexOf('.') !== -1) {
    const [context, f] = method.split('.');
    if (!tracker[context] || !tracker[context][f]) {
      log('Invalid action', method);
      return;
    }
    actionRes = tracker[context][f](...args);
  } else if (!tracker[method]) {
    log('Invalid action', method);
    return;
  } else {
    actionRes = tracker[method](...args);
  }

  if (actionRes != null) {
    log('Action result', actionRes);
  }
};

const applyActions = (...actions) => {
  actions.forEach(applyAction);
};

export default function initapplyActions() {
  if (window.extellio_actions.push !== applyActions) {
    window.extellio_actions.forEach(applyAction);
    window.extellio_actions = {
      push: applyActions,
    };
  }
}
