export default (dist) => {
  if (!dist.opts.excludedUrls || dist.opts.excludedUrls.length === 0) {
    return true;
  }

  const url = document.location.href;

  if (dist.opts.excludedUrls.some(e => url.match(e))) {
    return 'Current url matches an excluded url.';
  }

  return true;
};
