import { getSessionTime, getTotalTime } from './timeTracker';

let filtrex;

const inArray = (array, value, property) => {
  if (array) {
    return array.some(a => {
      if (property) {
        return filtrex.useDotAccessOperatorAndOptionalChaining(property, null, a, null) === value;
      }

      return a === value;
    });
  }
  return false;
};

const visitedUrl = (url) => {
  const visitedUrls = getTotalTime();
  return Object.keys(visitedUrls).some(u => u.match(url));
};

const visitedUrlThisSession = (url) => {
  const visitedUrls = getSessionTime();
  return Object.keys(visitedUrls).some(u => u.match(url));
};

export function compileCustomExpression(dist) {
  if (dist.opts.customExpression) {
    try {
      return filtrex.compileExpression(dist.opts.customExpression, {
        customProp: filtrex.useDotAccessOperatorAndOptionalChaining,
        extraFunctions: {
          inArray,
          visitedUrl,
          visitedUrlThisSession,
        },
      });
    } catch (e) {
      dist.log(e.message);
      return () => 'Error compiling custom expression.';
    }
  }
  return null;
}

export function loadCustomExpression() {
  const { dists } = window.extellio.data;

  if (dists && dists.some(dist => dist.customExpression)) {
    // __webpack_public_path__ = publicPath; // eslint-disable-line

    return import(/* webpackChunkName: "filtrex" */ './filtrex')
      .then((module) => {
        filtrex = module.default;
      });
  }

  return null;
}
