import iframeResizer from 'iframe-resizer/js/iframeResizer';

import postMessage from './postMessage';
import generateSurveyUrl from './generateSurveyUrl';
import '../styles/iframe.scss';

export default function createIframe(container, dist, minHeight, resolveOnFullyLoaded) {
  return new Promise((resolve) => {
    const wrapper = document.createElement('div');
    wrapper.setAttribute('class', 'extellio-iframe-wrapper');
    const iframe = document.createElement('iframe');

    iframe.setAttribute('src', generateSurveyUrl(dist, { embedded: true, modal: dist.opts.container === 'popupLayer' && dist.opts.containerType === 'modal' }));
    iframe.setAttribute('class', 'extellio-responsive-iframe');
    iframe.setAttribute('id', `extellio-${dist.opts.id}`);
    iframe.setAttribute('title', dist.opts.title);

    postMessage({ dist, container: iframe, resolve: resolveOnFullyLoaded && resolve });

    wrapper.appendChild(iframe);
    container.appendChild(wrapper);

    if (!resolveOnFullyLoaded) {
      iframe.onload = resolve;
    }

    iframeResizer({ scrolling: false, minHeight, checkOrigin: false }, iframe);
  });
}
