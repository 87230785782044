import { TrackPageViews } from 'shared/services/prisma/types';
import logger from '../utils/log';

const log = (...args) => logger('[Analytics]', ...args);

export default function onUrlChange({ initial }) {
  const { Matomo } = window;
  const { website, tracker, preview } = window.extellio;

  if (tracker && website.analytics && !preview) {
    if (website.trackPageViews === TrackPageViews.UrlChange || (website.trackPageViews === TrackPageViews.PageLoad && initial)) {
      if (website.reachedActionLimit) {
        log('Not tracking action because of license limit');
      } else {
        const url = document.location.href;
        tracker.setCustomUrl(url);
        if (!initial) {
          tracker.setDocumentTitle(document.title);
        }
        tracker.trackPageView();
        log('Track page view', website.trackPageViews, url);

        if (website.linkTracking) {
          tracker.enableLinkTracking();
          log('Link tracking enabled');
        }
      }
    }

    if (website.crashAnalytics) {
      Matomo.CrashAnalytics?.enable();
      log('Enabling Crash analytics');
    } else {
      Matomo.CrashAnalytics?.disable();
    }

    if (website.formAnalytics) {
      Matomo.FormAnalytics?.enableFormAnalytics();
      log('Enabling Form analytics');
    } else {
      Matomo.FormAnalytics?.disableFormAnalytics();
    }

    if (website.mediaAnalytics) {
      Matomo.MediaAnalytics?.enableMediaAnalytics();
      log('Enabling Media analytics');
    } else {
      Matomo.MediaAnalytics?.disableMediaAnalytics();
    }
  }
}
