export default (dist) => {
  if (dist.compiledCustomExpression) {
    try {
      const expressionResult = dist.compiledCustomExpression({
        window,
        distribution: dist.opts,
        data: window.extellio.data,
      });

      if (expressionResult === false) {
        return `Custom expression did not pass. ${dist.opts.customExpression}`;
      }

      if (expressionResult !== true) {
        return expressionResult;
      }
    } catch (e) {
      return `Custom expression threw an error: ${e.message}`;
    }
  }

  return true;
};
